.component-drawer-nav{
  padding: 10px;
  margin-top: 10px;
  .title-nav{
    box-sizing: border-box;
    list-style: none;
    padding: 24px 16px 8px;
    font-weight: 700;
    line-height: 1.5;
    font-size: 11px;
    text-transform: uppercase;
    font-family: "Public Sans", sans-serif;
    color: rgb(99, 115, 129);

    &.expt{
      margin-top: 50px;
    }
  }
}