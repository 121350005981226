.component-card-user-minimal{
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  align-items: center;
  padding: 16px 20px;
  border-radius: 12px;
  background-color: rgba(145, 158, 171, 0.12);
  .user-information{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title{
      margin: 0px;
      font-weight: 600;
      line-height: 1.57143;
      font-size: 0.875rem;
      font-family: "Public Sans", sans-serif;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .subtitle{
      margin: 0px;
      line-height: 1.57143;
      font-size: 10px;
      font-family: "Public Sans", sans-serif;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: rgb(99, 115, 129);
    }

  }
}