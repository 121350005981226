.view-array{
  margin-top:50px;
  width:100%;
  display: flex;

  .css-smntl9-MuiContainer-root {
    max-width: 100%;
    padding: 0 50px;
  }
}

