.view--log{
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  .title-log{
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .form-get-log{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .date-log{
      width: 100%;
      text-align: left;
      padding: 10px 5px;
      border: 2px solid #22286F;
      color: #22286F;
      background-color:transparent;
      font-size: 12px;
      margin-bottom:10px;
      border-radius:5px;
      ::-webkit-input-placeholder {
        color: #22286F;
      }
      &:focus{
        border: 1px solid #22286F;
      }
    }
    .button-log{
      background-color: #212b36;
      text-align: center;
      font-size: 16px;
      padding: 10px 5px;
      margin-left: 20px;
      margin-bottom:10px;
      cursor: pointer;
      border-radius: 10px;
      border: 1px solid #212b36;
      width: 100%;
      color: #FFFFFF;
    }

    .button-log-reset {
      background-color: orangered;
      text-align: center;
      font-size: 16px;
      padding: 10px 5px;
      margin-left: 20px;
      margin-bottom:10px;
      cursor: pointer;
      border-radius: 10px;
      border: 1px solid #212b36;
      width: 100%;
      color: #FFFFFF;
    }

  }
  .container-log{
    display: flex;
    flex-direction: column;
    background: #000;
    height: 700px;
    overflow-x: scroll;
    border-radius: 10px;
    .pre{
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      margin-left: 10px;
      .json{
        margin-left: 20px;
        padding: 5px;
        color: orangered;
      }
    }
  }

}
