.view--health-check{
  display: flex;
  flex-direction: column;
  padding: 30px;
  .title-header{
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .container-health-check{
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
  }
}