.component-card-stats-number{
  padding: 10px 20px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-container{
    display: flex;
    flex-direction: column;
    .title-name{
      font-size: 15px;
      color: rgb(33, 43, 54);
      
    }
  }
}