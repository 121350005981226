.container-modal{
  padding:20px;
  display:flex;
  flex-direction:column;
  background-color:#fff;
  border-radius:10px;
  .header-modal{
    display:flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom:30px;
    .title-header{
      font-size:16px;
      font-weight:bold;
      color: #22286F;
    }
    .button-close{
      width:30px;
      height:30px;
      cursor: pointer;
    }
  }
  .form-container{
    max-height: 500px;
    overflow-x: scroll;
    display:flex;
    flex-direction:column;
    .title-alone{
      font-size:12px;
      font-weight:500;
      color: #22286F;
      margin-bottom: 10px;
    }
    .container-input-modal{
      display:flex;
      flex-direction:column;
      margin-top: 10px;
      .title-upload{
        margin-bottom: 20px;
        font-size: 16px;
        color: black;
      }
      .file-drop-area {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 100%;
        padding: 25px;
        border: 1px dashed rgba(34, 10, 110, 0.4);
        border-radius: 3px;
        transition: 0.2s;
        &.is-active {
          background-color: rgba(34, 10, 110, 0.05);
        }
      }

      .fake-btn {
        flex-shrink: 0;
        background-color: rgba(34, 10, 110, 0.04);
        border: 1px solid rgba(34, 10, 110, 0.1);
        border-radius: 3px;
        padding: 8px 15px;
        margin-right: 10px;
        font-size: 12px;
        text-transform: uppercase;
      }

      .file-msg {
        font-size: small;
        font-weight: 300;
        line-height: 1.4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .file-input {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        opacity: 0;
        &:focus {
          outline: none;
        }
      }
      .title-input{
        font-size:12px;
        font-weight:500;
        color: #22286F;

        margin-bottom: 10px;
      }
      .input-container-modal{
        width: 100%;
        text-align: left;
        padding: 10px 5px;
        border: 2px solid #22286F;
        color: #22286F;
        background-color:transparent;
        font-size: 12px;
        margin-bottom:10px;
        border-radius:5px;
        ::-webkit-input-placeholder {
          color: #22286F;
        }
        &:focus{
          border: 1px solid #22286F;
        }
      }
    }
    .button-upload{
      background-color: #212b36;
      text-align: center;
      font-size: 16px;
      padding: 20px 10px;
      cursor: pointer;
      border-radius: 10px;
      margin-top: 20px;
      border: 1px solid #212b36;
      width: 100%;
      color: #FFFFFF;
    }
  }
}