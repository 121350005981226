


//Lib


// Utils
@import "./utils/reset";
@import "./utils/sizes";
@import "./utils/mixins";
@import "./utils/_settings";
@import "./utils/_color";
@import "./utils/mui";

// Layouts
@import "../layouts/drawer-nav/drawernav";


// Components
@import "../components/card_user_minimal/carduserminal.scss";
@import "../components/card_stats_number/cardstatsnumber.scss";
@import "../components/widget_card_information/widgetcardinformation.scss";
@import "./component/modal/modal.scss";
@import "../components/loader/loader.scss";

// Pages
@import "../views/login/login.scss";
@import "../views/dashboard/dashboard";
@import "../views/finance/price/price.scss";
@import "../views/finance/product/product.scss";
@import "../views/finance/subsciption/subscription.scss";
@import "../views/cards/cards";
@import "../views/enterprise/enterprise.scss";
@import "../views/profile/profile.scss";
@import "../views/settings/settings.scss";
@import "../views/user/user.scss";
@import "../views/address/address.scss";
@import "../views/groupe/group.scss";
@import "../views/health-check/health-check.scss";
@import "../views/log/log.scss";
@import "../views/mail/mail.scss";
@import "../views/media/media.scss";
@import "../views/occupation/occupation.scss";
@import "../views/profile-g/profile-g.scss";
@import "../views/social-network/social-network.scss";




#app {
  height: 100%;
}
body {
  background: #f0eef2;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}









