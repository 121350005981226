.view--profile{
  display: flex;
  flex-direction: column;
  padding: 100px 50px;
  .container-profile{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .left-part{
      width: calc(30% - 30px);
    }
    .right-part{
      width: calc(70% - 30px);
    }
  }
}